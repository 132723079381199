// This file is automatically compiled by Webpack, along with any other files
// present in this directory. You're encouraged to place your actual application logic in
// a relevant structure within app/javascript and only use these pack files to reference
// that code so it'll be compiled.

window.$ = window.jQuery = require('jquery');

require("@rails/ujs").start()
// require("turbolinks").start()
require("@rails/activestorage").start()
require("channels")

import '@fortawesome/fontawesome-free/js/all';
// import 'packs/vender/stripe-form.js';
import 'packs/common/modal.js';
// Uncomment to copy all static images under ../images to the output folder and reference
// them with the image_pack_tag helper in views (e.g <%= image_pack_tag 'rails.png' %>)
// or the `imagePath` JavaScript helper below.
//
// const images = require.context('../images', true)
// const imagePath = (name) => images(name, true)

$(window).on('load resize', function(){ //読み込みかリサイズしたとき
  $('.body-content-area').css('min-height', window.innerHeight - $('footer').outerHeight());
});

$(window).ready(function(){
  $(".scroll_btn").on("click", function(){
    window.scrollTo({
      top: 0,
      behavior: 'smooth'
    });
  });
})